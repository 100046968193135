import React from 'react';
import Theme from '../components/PageCommon/Theming/ThemeProvider';
import PageContainer from '../components/PageCommon/PageContainer/PageContainer';
import PageMeta from '../components/PageCommon/PageMeta/PageMeta';
import Footer from '../components/PageCommon/Footer/Footer';
import MainContainer from '../components/PageCommon/MainContainer/MainContainer';
import NavBar from '../components/PageCommon/NavBar/NavBar';
import CommonPageLayout from '../components/PageLayouts/Common/CommonPageLayout';

import useLatestThoughtsPosts from '../hooks/useLatestThoughtsPosts';
import useSiteMetadata from '../hooks/useSiteMetadata';

const ThoughtsPage = () => {
    const postsData = useLatestThoughtsPosts();

    const { siteUrl } = useSiteMetadata();
    const pageTitle = 'Pārdomas';
    const url = siteUrl + '/thoughts';
    const pageDescription = "Bolg posts tagged with 'thoughts'";

    return (
        <Theme>
            <PageMeta title={pageTitle} url={url} pageDescription={pageDescription} />
            <PageContainer>
                <NavBar page="thoughts" />
                <MainContainer>
                    <CommonPageLayout postsData={postsData} />
                </MainContainer>
                <Footer />
            </PageContainer>
        </Theme>
    );
};

export default ThoughtsPage;
